import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { RootLayout } from "../root/root.component";
import * as $ from "jquery";

@Component({
  selector: "simplywhite-layout",
  templateUrl: "./simplywhite.component.html",
  styleUrls: ["./simplywhite.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {
  notificationSize: number = 0;
  errors: boolean = false;
  showNotifications: boolean = false;
  menuLinks = [
    {
      label: "Dashboard",
      routerLink: "/dashboard",
      roles: [1, 2, 3],
      iconType: "fal",
      iconName: "fa-tachometer-alt-fast",
    },
    {
      label: "Reports",
      iconType: "fal",
      iconName: "far fa-abacus",
      toggle: "close",
      roles: [1],
      mToggle: "close",
      submenu: [
        {
          label: "Sales Report",
          routerLink: "/reports/sales",
          iconType: "letter",
          iconName: "s",
          roles: [1],
        },
        {
          label: "Debtors Report",
          routerLink: "/reports/debtors",
          iconType: "letter",
          iconName: "d",
          roles: [1],
        },
        // {
        // 	label: "Stock Report",
        // 	routerLink: "/sales/client",
        // 	iconType: "letter",
        // 	iconName: "c",
        // 	roles: [1],
        // },
      ],
    },
    {
      label: "Paint",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-paint-roller",
      toggle: "close",
      mToggle: "close",
      submenu: [
        {
          label: "Paint Dashboard",
          routerLink: "/paint/dashboard",
          iconType: "fal",
          iconName: "fa-tachometer-alt-fast",
          roles: [1, 2, 3],
        },
        {
          label: "Purchase Request",
          routerLink: "/paint/purchase-request",
          searchParams: {
            q: "paint",
          },
          iconType: "letter",
          iconName: "po",
          roles: [1, 2, 3],
        },
        {
          label: "Recipe",
          routerLink: "/paint/recipe",
          iconType: "letter",
          iconName: "r",
          roles: [1, 2, 3],
        },
        {
          label: "Manufacture",
          routerLink: "/paint/manufacture",
          iconType: "letter",
          iconName: "m",
          roles: [1, 2, 3],
        },
        {
          label: "Raw Material",
          routerLink: "/paint/raw-material",
          iconType: "fal",
          iconName: "far fa-box-open",
          roles: [1, 2, 3],
        },
        {
          label: "Stock",
          routerLink: "/stock/type/paint",
          iconType: "fal",
          iconName: "far fa-box-open",
          roles: [1, 2, 3],
        },
      ],
    },
    {
      label: "Bricks",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-chimney",
      toggle: "close",
      mToggle: "close",
      submenu: [
        {
          label: "Purchase Request",
          routerLink: "/bricks/purchase-request",
          iconType: "letter",
          iconName: "po",
          roles: [1, 2, 3],
        },
        {
          label: "Stock",
          routerLink: "/stock/type/bricks",
          iconType: "fal",
          iconName: "far fa-box-open",
          roles: [1, 2, 3],
        },
      ],
    },
    {
      label: "Bags",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-sack",
      toggle: "close",
      mToggle: "close",
      submenu: [
        {
          label: "Purchase Request",
          routerLink: "/bags/purchase-request",
          iconType: "letter",
          iconName: "po",
          roles: [1, 2, 3],
        },
        {
          label: "Recipe",
          routerLink: "/paint/recipe",
          iconType: "letter",
          iconName: "r",
          roles: [1, 2, 3],
        },
        {
          label: "Stock",
          routerLink: "/stock/type/bags",
          iconType: "fal",
          iconName: "far fa-box-open",
          roles: [1, 2, 3],
        },
      ],
    },
    // {
    // 	label: "Paint",
    // 	iconType: "fal",
    // 	iconName: "far fa-copy",
    // 	toggle: "close",
    // 	roles: [1, 2, 3],
    // 	mToggle: "close",
    // 	submenu: [
    // 		{
    // 			label: "Order",
    // 			routerLink: "/paint/order",
    // 			iconType: "letter",
    // 			iconName: "c",
    // 			roles: [1, 2, 3],
    // 		},
    // 		{
    // 			label: "Recipe",
    // 			routerLink: "/paint/recipe",
    // 			iconType: "letter",
    // 			iconName: "t",
    // 			roles: [1, 2, 3],
    // 		}
    // 	]
    // },
    {
      label: "Sales",
      iconType: "fal",
      iconName: "far fa-cubes",
      toggle: "close",
      roles: [1, 2, 3],
      mToggle: "close",
      submenu: [
        {
          label: "New Sales Order",
          routerLink: "/sales/order/new",
          iconType: "letter",
          iconName: "c",
          roles: [1, 2, 3],
        },
        {
          label: "Sales Order",
          routerLink: "/sales/order",
          iconType: "letter",
          iconName: "t",
          roles: [1, 2, 3],
        },
        {
          label: "Client Account",
          routerLink: "/sales/client",
          iconType: "letter",
          iconName: "c",
          roles: [1, 2, 3],
        },
        {
          label: "Order Request",
          routerLink: "/sales/order-request",
          iconType: "letter",
          iconName: "or",
          roles: [1, 2, 3],
        },
      ],
    },

    {
      label: "Buying",
      iconType: "fal",
      iconName: "far fa-money-bill",
      toggle: "close",
      roles: [1, 2, 3],
      mToggle: "close",
      submenu: [
        {
          label: "Purchase Requests",
          routerLink: "/buying/purchase-request",
          iconType: "letter",
          iconName: "pr",
          roles: [1, 2, 3],
        },
        {
          label: "Pending Purchase Requests",
          routerLink: "/buying/purchase-request/pending",
          iconType: "fal",
          iconName: "far fa-clock",
          roles: [1, 2, 3],
        },
        {
          label: "Reverted Purchase Requests",
          routerLink: "/buying/purchase-request/reverted",
          iconType: "fal",
          iconName: "far fa-backward",
          roles: [1, 2, 3],
        },
      ],
    },
    {
      label: "Accounts",
      iconType: "fal",
      iconName: "far fa-copy",
      toggle: "close",
      roles: [1, 2, 3],
      mToggle: "close",
      submenu: [
        {
          label: "Banks",
          routerLink: "/accounts/banks",
          iconType: "letter",
          iconName: "b",
          roles: [1, 2, 3],
        },
        {
          label: "Expenses",
          routerLink: "/accounts/expenses",
          iconType: "letter",
          iconName: "e",
          roles: [1, 2, 3],
        },
        {
          label: "Purchase Orders",
          routerLink: "/accounts/purchase-orders",
          iconType: "letter",
          iconName: "po",
          roles: [1, 2, 3],
        },
        {
          label: "Chart of Accounts",
          routerLink: "/accounts/chart-of-accounts",
          iconType: "letter",
          iconName: "c",
          roles: [1, 2, 3],
        },
        {
          label: "Stock Value",
          routerLink: "/accounts/stock-value",
          iconType: "letter",
          iconName: "sv",
          roles: [1, 2, 3],
        },
      ],
    },
    // {
    // 	label: "Equipment",
    // 	toggle: "close",
    // 	iconType: "fal",
    // 	iconName: "far fa-hammer",
    // 	mToggle: "close",
    // 	roles: [1, 2, 3],
    // 	subMenu: [
    // 		{
    // 			label: "Equipment List",
    // 			routerLink: "/equipment/",
    // 			iconType: "fal",
    // 			iconName: "far fa-truck",
    // 			roles: [1, 2, 3],
    // 		},
    // 		{
    // 			label: "Equipment Categories",
    // 			routerLink: "/equipment/categories",
    // 			iconType: "letter",
    // 			iconName: "p",
    // 			roles: [1, 2, 3],
    // 		},
    // 		{
    // 			label: "Equipment Notification",
    // 			routerLink: "/equipment/notifications",
    // 			iconType: "letter",
    // 			iconName: "h",
    // 			roles: [1, 2, 3],
    // 		},
    // 	]
    // },

    {
      label: "Equipment",
      iconType: "fal",
      iconName: "far fa-hammer",
      toggle: "close",
      roles: [1, 2, 3],
      mToggle: "close",
      submenu: [
        {
          label: "New Equipment",
          routerLink: "/equipment/new",
          iconType: "fal",
          iconName: "far fa-plus",
          roles: [1, 2, 3],
        },
        {
          label: "Equipment List",
          routerLink: "/equipment",
          iconType: "fal",
          iconName: "far fa-list",
          roles: [1, 2, 3],
        },
        {
          label: "Equipment Categories",
          routerLink: "/equipment/categories",
          iconType: "fal",
          iconName: "far fa-clipboard-list",
          roles: [1, 2, 3],
        },
        {
          label: "Equipment Notifications",
          routerLink: "/equipment/notifications",
          iconType: "fal",
          iconName: "far fa-bell",
          roles: [1, 2, 3],
        },
      ],
    },

    // {
    // 	label: "Purchase Orders",
    // 	routerLink: "/purchase-order",
    // 	roles: [1, 2, 3],
    // 	iconType: "fal",
    // 	iconName: "far fa-copy",
    // },
    {
      label: "Stock",
      routerLink: "/stock",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-box-open",
    },
    // {
    // 	label: "Purchase Orders",
    // 	routerLink: "/purchase-order",
    // 	roles: [1, 2],
    // 	iconType: "fal",
    // 	iconName: "far fa-money-check-edit",
    // },
    {
      label: "Deliveries",
      toggle: "close",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-truck",
      mToggle: "close",
      submenu: [
        {
          label: "Add New Delivery",
          routerLink: "/delivery/new",
          iconType: "fal",
          iconName: "far fa-truck",
          roles: [1, 2, 3],
        },
        {
          label: "Pending",
          routerLink: "/delivery/pending",
          iconType: "letter",
          iconName: "p",
          roles: [1, 2, 3],
        },
        {
          label: "History",
          routerLink: "/delivery/history",
          iconType: "letter",
          iconName: "h",
          roles: [1, 2, 3],
        },
        {
          label: "Incidents",
          routerLink: "/delivery/incidents",
          iconType: "letter",
          iconName: "i",
          roles: [1, 2, 3],
        },
      ],
    },
    {
      label: "Stock Suppliers",
      routerLink: "/supplier",
      roles: [1, 2],
      iconType: "fal",
      iconName: "far fa-truck-loading",
    },
    {
      label: "Settings",
      iconType: "fal",
      iconName: "far fa-gear",
      toggle: "close",
      mToggle: "close",
      roles: [1, 2, 3],
      submenu: [
        {
          label: "Users",
          routerLink: "/user",
          roles: [1, 2],
          iconType: "fal",
          iconName: "far fa-users",
        },
        {
          label: "Stock Location",
          routerLink: "/location",
          roles: [1, 2],
          iconType: "fal",
          iconName: "far fa-location",
        },
        {
          label: "Departments",
          routerLink: "/department",
          roles: [1, 2],
          iconType: "fal",
          iconName: "far fa-industry-windows",
        },
      ],
    },
  ];
  ngOnInit() {
    this.changeLayout("menu-pin");
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
    // document.documentElement.style.setProperty('--primary-color', '#546E87');
    // document.documentElement.style.setProperty('--secondary-color', '#7DA1B5');
    this.filterMenu(this.authService);
    // this.getNotificationSize();
  }

  getLogo() {
    return this.authService.getCompanyLogo();
  }

  isAuthenticated() {
    return this.authService.authenticated;
  }

  signout() {
    this.authService.signout();
  }

  isSystemAdmin(): boolean {
    if (this.authService.getUserRole() == 1) {
      return true;
    }
    return false;
  }

  getNotificationSize() {
    let _this = this;
    this.notificationService.getCount().subscribe(
      (res) => {
        _this.notificationSize = res["data"]["count"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getFullName() {
    if (!this.authService.user) {
      return "";
    }
    console.log("Logged in as" + this.authService.user.name);
    return this.authService.user.name;
  }

  filterMenu(authService) {
    let role = authService.getUserRole();
    for (let parent = 0; parent < this.menuLinks.length; parent++) {
      if (!this.menuLinks[parent].roles.includes(role)) {
        this.menuLinks[parent] = null;
      }
    }
    this.menuLinks = this.menuLinks.filter(Boolean);
  }
}
