import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/stock`,{params: searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/stock/`+id);
    }

    stockValue(params){
      return this.http.get(`${environment.baseUrl}/reports/stockvalue`,{params: params});
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/stock/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/stock`,formData);
	}
	
    restock(formData){
      return this.http.post(`${environment.baseUrl}/stock/restock`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/stock/`+id);
    }


}
