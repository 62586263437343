import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleService } from './role.service';
import * as $ from 'jquery';

@Injectable()
export class RoleGuard implements CanActivate {
  returnUrl: string;

  constructor(
    private authService: AuthService,
    private rolesService: RoleService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userRoleId: number = this.authService.getUserRole();
    const userRole = this.rolesService.getAccess(userRoleId);
    //console.log(userRoleId+' '+userRoleId);
    // if(confirm("Are you sure?")){

    // }
    if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
      //console.log(state.url);
      if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
        return false;
      }
    }
    if (userRole) {
      if (userRole.routeAccess[state.url.split('/')[1]]) {
        return true;
      }
      console.warn('Access Denied. Navigated to default route');

      const defaultRoute = this.rolesService.getAccess(userRoleId).defaultRoute;
      this.router.navigate([`/${defaultRoute}`]);
      return true;
    } else {
      console.log(`User roles: [${userRole}] not recognized`);
      this.router.navigate(['/session/login']);
      return false;
    }
  }
}
