import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
	public roles: Roles = {
		1: {
			role: 'Administrator',
			defaultRoute: '/user',
			routeAccess: {
				department: true,
				dashboard: true,
				stock: true,
				user: true,
				support: true,
				profile: true,
				supplier: true,
				stocktype: true,
				door: true,
				order: true,
				paint: true,
				sales: true,
				reports: true,
				extra: true,
				client: true,
				accounts: true,
				notification: true,
				stockcolor: true,
				delivery: true,
				['purchase-order']: true,
				['sales-order']: true,
			}
		}
	};

	constructor() { }

	getAccess(role: number): RoleAccess {
		return <RoleAccess>this.roles[role];
	}
}
